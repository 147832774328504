/*
example of a full exhibition object:
{
    year: "2022 Exhibition Schedule",
    exhibition: "Solo booth 'Peoplescapes' The Other Art Fair, ",
    location: "The Barker Hangar, Santa Monica, CA USA",
    date: "22nd-25th September 2022",
    link: { theLink: "/bio", linkText: "Here is the Link" },
    linkOut: { theLink: "http://www.google.com", linkText: "Link to google" },
  }

  note that for a link out you must include the full url including http:// or https://
*/

const groupExhibitions = [
  {
    year: "2024",
    exhibition: "The Other Art Fair",
    location: "ZeroSpace, 337-345 Butler St, Brooklyn USA",
    date: "16th - 19th May 2024",
  },
  {
    year: "2023",
    exhibition: "The Other Art Fair",
    location: "ZeroSpace, 337-345 Butler St, Brooklyn USA",
    date: "9th - 12th November 2023",
  },
  {
    exhibition: "Art Market San Francisco, Maune Contemporary",
    location: "Fort Mason Center, 2 Marina Blvd, San Francisco USA",
    date: "20th - 23rd April 2023",
  },
  {
    exhibition: "The Other Art Fair",
    location: "Brooklyn Navy Yard, Agger Fish, Brooklyn USA",
    date: "18th - 21st May 2023",
  },
  {
    year: "2022",
    exhibition: "The Other Art Fair",
    location: "Brooklyn Navy Yard, Agger Fish, Brooklyn USA",
    date: "3rd - 6th November 2022",
  },
  {
    exhibition: "The Other Art Fair",
    location: "The Barker Hangar, Santa Monica, Los Angeles USA",
    date: "22nd - 25th September 2022",
  },
  {
    year: "2020",
    exhibition: "Solo booth 'Peoplescapes' The Other Art Fair",
    location: "Brooklyn Expo Center, Greenpoint, NY USA",
    date: "30th April - 3rd May 2020 (Cancelled due to COVID-19)",
  },

  {
    year: "2019",
    exhibition: "Solo Booth 'Abstracts' The Other Art Fair",
    location: "Barangaroo, Sydney Australia",
    date: "24 - 27th October 2019",
  },
  {
    exhibition: "Solo Booth 'Peoplescapes' The Other Art Fair",
    location: "The Barker Hangar, Santa Monica, CA USA",
    date: "5 - 8th September 2019",
  },

  {
    exhibition: "Auckland Art Fair",
    location: "Warwick Henderson Gallery, Auckland NZ",
    date: "1st - 5th May 2019",
  },

  {
    exhibition: "Affordable Art Fair NYC",
    location: "Rebecca Hossack Art Gallery, NY USA",
    date: "28th - 31st March 2019",
  },

  {
    exhibition: 'Solo Booth "New Paintings" The Other Art Fair',
    location: "Australian Technology Park, Sydney Australia",
    date: "14 - 17th March 2019",
  },
  {
    year: "2018",
    exhibition: "Affordable Art fair NYC",
    location: "Rebecca Hossack Art Gallery, New York USA",
    date: "27th - 30th September 2018",
  },
  {
    exhibition: "The City",
    location: "Rebecca Hossack Art Gallery, London UK",
    date: "2nd - 26th May 2018",
  },

  {
    exhibition: "The Other Art Fair",
    location: "The Majestic. Downtown. Los Angeles. US",
    date: "15th - 18th March 2018",
  },

  {
    year: "2017",
    exhibition: "Affordable Art fair Battersea",
    location: "Rebecca Hossack Art Gallery, London UK",
    date: "19th - 22nd October 2017",
  },
  {
    exhibition: "The Other Art Fair",
    location: "The Facility, 2 Chelmsford St, Kensington, Melbourne Australia",
    date: "4th - 7th May 2017",
  },
  {
    year: "2015",
    exhibition: "The Other Art Fair",
    location: "Central Park, Sydney",
    date: "10 - 13th September 2015",
  },
  {
    exhibition: "The Figurative Show",
    location: "The Vivian, 39 Omaha Valley Rd, Matakana NZ",
    date: "28th March - 26th April 2015",
  },
  {
    year: "2013",
    exhibition: "Amalgam",
    location: "George Contemporary Art & Design, 338 Hackney Rd, London UK",
    date: "5th - 30th September 2013",
  },
  {
    exhibition: "Troll Im Dachgeschoss",
    location: "Bucker Project Space, Berlin Germany",
    date: "22nd March 2013",
  },
  {
    year: "2011",
    exhibition: "Auckland Art Fair",
    location: "Warwick Henderson Gallery, Auckland NZ",
    date: "4th - 7th August 2011",
  },
  {
    year: "2010",
    exhibition:
      "Group Show/Book Launch for 'NZ Gallery - by Denis Robinson' Harrisons Gallery",
    location: "Tauranga NZ",
    date: "20th October - 3rd November 2010",
  },
  {
    year: "2009",
    exhibition: "Wallace Art Awards - Salon des Refusés",
    location: "The James Wallace Arts Trust Gallery Auckland NZ",
    date: "7th September - 2nd October 2009",
  },
  {
    exhibition: "Auckland Art Fair",
    location: "Warwick Henderson Gallery Auckland. NZ",
    date: "3rd May 2009",
  },
  {
    exhibition: "Autumn Catalogue group exhibition",
    location: "Warwick Henderson Gallery Auckland NZ",
    date: "29th April - 23rd May 2009",
  },
  {
    exhibition: "REAL and UNREAL (An exhibition of Surrealists)",
    location: "Warwick Henderson Gallery Auckland NZ",
    date: "1st April - 24th April 2009",
  },
  {
    year: "2008",
    exhibition: "Summer catalogue group exhibition",
    location: "Warwick Henderson Gallery Auckland NZ.",
    date: "December 9th 2008 - February 7th 2009.",
  },
  {
    year: "2007",
    exhibition: "FYP group graduation show",
    location: "James Wallace Trust Gallery, Auckland NZ",
    date: "6th December 2007",
  },
  {
    exhibition: "UNITEC Graduation show",
    location: "Building 1 Auckland NZ",
    date: "28th November 2007",
  },
];

export default groupExhibitions;

/*
    exhibition:
    location:
    date:
*/
