import i1 from "../../images/shop/25thprintNYC/1_nycSuperheroes.jpg";
import i2 from "../../images/shop/25thprintNYC/2_nycSuperheroes.jpg";
import i3 from "../../images/shop/25thprintNYC/3_nycSuperheroes.jpg";
import i4 from "../../images/shop/25thprintNYC/4_nycSuperheroes.jpg";
import i5 from "../../images/shop/25thprintNYC/5_nycSuperheroes.jpg";
import i6 from "../../images/shop/25thprintNYC/6_nycSuperheroes.jpg";
import i7 from "../../images/shop/25thprintNYC/7_nycSuperheroes.jpg";

const nycSuperheroesCollection = [
  { image: i1, alt: "The Superheroes and a photoshoot" },
  { image: i2, alt: "The Superheroes and a photoshoot" },
  { image: i3, alt: "The Superheroes and a photoshoot" },
  { image: i4, alt: "The Superheroes and a photoshoot" },
  { image: i5, alt: "The Superheroes and a photoshoot" },
  { image: i6, alt: "The Superheroes and a photoshoot" },
  { image: i7, alt: "The Superheroes and a photoshoot" },
];

export default nycSuperheroesCollection;
