import React, { useEffect } from "react";
import imageScroller from "./../../../utils/imageScroller";
import HeaderSpacer from "./../../../components/HeaderSpacer";
import MiniCapsuleOriginalCollection from "./../../../data/shop/miniCapsuleOriginal_Data";
import BackButton from "./../../../components/BackButton";
import Square from "../../../components/Square";
import SquareSpacer from "../../../components/SquareSpacer";
import SlideShow from "./../../../components/SlideShow";
import { Helmet } from "react-helmet-async";



function MiniCapsuleOriginal () {
  useEffect(() => {
    imageScroller("App");
  });

  return (
    <div>
      <Helmet>
        <title>TL | 10inch x 10inch Capsule Series 2024</title>
        <meta property='og.title' content='10inch x 10inch Capsule Series 2024' />
        <meta property='og.image' content={MiniCapsuleOriginalCollection[0].image} />
      </Helmet>
      <HeaderSpacer />
      <p>
        <strong>10inch x 10inch Capsule Series 2024 </strong>
        
      </p>
      <p>Each Painting is...</p>
      <p>Oil & acrylic on linen</p>
      <p>25.4cm x 25.4cm (10" x 10")</p>
      <p>Italian linen stretched on pine stretcher bars ready to hang.</p>
      <p>
        <strong>$780AUD each</strong>
      </p>
      <p>Free international shipping</p>
      <p>Click on Buy Now button below to see which paintings are still available.</p>
      <br></br>
      
      <SlideShow
        slides={MiniCapsuleOriginalCollection}
        slideShowName='miniCapsuleSlides'
        startingIndex={0}
        scrollOffset={-20}
      />

      <Square
        thePrice={"$780"}
        theTitle={"Untitled - Oil & acrylic on linen"}
        theLink={"https://tyronelayne.square.site/shop/10-x-10-painting-capsule-2024/OBRXD3HO6BPGFR6Q6VDLXC5Y"}
      />
      <SquareSpacer />
      
      <BackButton />
    </div>
  );
}

export default MiniCapsuleOriginal;
