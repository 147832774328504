import React, { useEffect } from "react";
import imageScroller from "./../../../utils/imageScroller";
import HeaderSpacer from "./../../../components/HeaderSpacer";
import parisOriginalCollection from "./../../../data/shop/parisOriginal_Data";
import BackButton from "./../../../components/BackButton";
import Square from "../../../components/Square";
import SquareSpacer from "../../../components/SquareSpacer";
import renderSoldLabel from "./../../../components/SoldText";
import SlideShow from "./../../../components/SlideShow";
import { Helmet } from "react-helmet-async";



function ParisOriginal() {
  useEffect(() => {
    imageScroller("App");
  });

  return (
    <div>
      <Helmet>
        <title>TL | Paris: A Moveable Feast</title>
        <meta property='og.title' content='Paris: A Moveable Feast- 2024' />
        <meta property='og.image' content={parisOriginalCollection[0].image} />
      </Helmet>
      <HeaderSpacer />
      <p>
        <strong>Paris: A Moveable Feast- 2024 </strong>
        {renderSoldLabel(true)}
      </p>
      <p>Oil & acrylic on linen</p>
      <p>71cm x 71cm (28" x 28")</p>
      <p>Stretched linen on pine wood stretcher bars ready to hang.</p>
      <p>
        <strong>$3,750AUD</strong>
      </p>
      <p>Free international shipping</p>
      <br></br>
      
      <SlideShow
        slides={parisOriginalCollection}
        slideShowName='parisOriginalSlides'
        startingIndex={0}
        scrollOffset={-20}
      />

      <Square
        thePrice={"$3,750.00"}
        theTitle={"Paris: A Moveable Feast - Oil & acrylic on linen"}
        theLink={"https://square.link/u/OOeHMIZt?src=embed"}
      />
      <SquareSpacer />
      
      <BackButton />
    </div>
  );
}

export default ParisOriginal;
