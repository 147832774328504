/*
example of a full exhibition object:
{
    year: "2022 Exhibition Schedule",
    exhibition: "Solo booth 'Peoplescapes' The Other Art Fair, ",
    location: "The Barker Hangar, Santa Monica, CA USA",
    date: "22nd-25th September 2022",
    link: { theLink: "/bio", linkText: "Here is the Link" },
    linkOut: { theLink: "http://www.google.com", linkText: "Link to google" },
  }

  note that for a link out you must include the full url including http:// or https://
*/

const soloExhibitions = [
  {
    exhibition: "Upcoming - TIME MACHINE",
    location: "50 Buckingham St, Surry Hills, Sydney AU",
    date: "4th - 15th February 2025",
  },
  {
    exhibition: "Sydney Siders, Unscripted",
    location: "Online due to COVID-19",
    // link: { theLink: "/sydneysiders", linkText: "Go to Exhibition" },
    date: "September 2021",
  },

  {
    exhibition: "Dystopia - Abstracts",
    location: "DePlume Gallery, East Hollywood, Los Angeles USA",
    date: "26th January - 2nd March 2019",
  },
  {
    exhibition: "Eastern Suburbs",
    location: "Saint Cloche. Paddington, Sydney AU",
    date: "24th May - 4th June 2017",
  },
  {
    exhibition: "Lawyers, Bankers, & Beach Bums",
    location: "Saint Cloche, Paddington, Sydney AU",
    date: "11th - 29th May 2016",
  },
  {
    exhibition: "This is Auckland",
    location: "Warwick Henderson Gallery. Auckland. NZ",
    date: "25th November - 13th December 2014",
  },
  {
    exhibition: "Made in London",
    location: "147 - 149 Farringdon Rd, London UK",
    date: "19th - 23rd September 2013",
    // link: { theLink: "/madeInLondon", linkText: "Photograph" },
  },
  {
    exhibition: "People",
    location: "Warwick Henderson Gallery, Auckland. NZ",
    date: "27th September - 15th October 2011",
  },
  {
    exhibition: "Longer Hotter Summer",
    location: "Warwick Henderson Gallery, Auckland. NZ",
    date: "12th October - 9th November 2010",
  },
  {
    exhibition: "Reasonable Doubt",
    location: "Warwick Henderson Gallery, Auckland NZ",
    date: "27th October - 14th November 2009",
  },
];

export default soloExhibitions;
