import React from "react";
import Square from "../../../components/Square";
import SquareSpacer from "../../../components/SquareSpacer";
import { Helmet } from "react-helmet-async";
import HeaderSpacer from "../../../components/HeaderSpacer";

import SlideShow from "../../../components/SlideShow";
import BackButton from "./../../../components/BackButton";
import nycSuperheroesCollection from './../../../data/shop/nycSuperheroes_Data';

function NycSuperheroes() {
  return (
    <div>
      <Helmet>
        <title>TL | The Superheroes and a photoshoot</title>
        <meta property='og.title' content='The Superheroes and a photoshoot - 2024' />
        <meta property='og.image' content={nycSuperheroesCollection[0].image} />
      </Helmet>
      <HeaderSpacer />
      <p>
        {" "}
        <strong>The Superheroes and a photoshoot - 2024.</strong>
      </p>

      <p>
        <strong>Limited Edition of 12 prints +1 A.P.</strong>
      </p>

      <p>
        Giclee archival pigment print on 310 gsm 100% cotton rag fine art
        archival paper.
      </p>

      <p>Print Size 54cm x 54cm / 21.26in x 21.26in.</p>

      <p>Paper Size 60cm x 60cm / 23.62in x 23.62in.</p>

      <p>
        Signed and numbered on the front bottom right of paper, and on the
        reverse side of paper.
      </p>

      <p>
        <strong>$185 AUD each </strong>- Unframed.
      </p>

      <p>FREE postage within Australia only.</p>

      <p>$15 shipping to NZ & $25 anywhere else in the world.</p>

      <p>Item is rolled up and shipped in a tube via Australia Post.</p>

      <p>Allow 4-7 working days before shipment is dispatched.</p>

      <SlideShow
        slides={nycSuperheroesCollection}
        slideShowName='nycSuperheroes'
        startingIndex={0}
        slideHeight='slideShort'
        scrollOffset={-70}
        shopSlideShow={true}
      />
      <Square
        thePrice='$185.00 - $210.00'
        theTitle='The Superheroes and a photoshoot- 2024'
        theLink='https://square.link/u/9BSmJXlc?src=embed'
      />
      <SquareSpacer />
      <BackButton />
    </div>
  );
}

export default NycSuperheroes;
