import React from "react";
import HeaderSpacer from "../components/HeaderSpacer";
import betootaPic from "../images/shop/Stockroom/1_betootaHeights.jpg";
import optimist2Pic from "../images/shop/Stockroom/2_theOptimistII.jpg";
import parisOriginalPic from "../images/shop/Stockroom/1_parisOriginal.JPG";
import miniCapsulePic from "../images/shop/Stockroom/1_10x10Capsule.JPG";
import ImageLink from "../components/ImageLink";
import UpButton from "./../components/UpButton";
import { Helmet } from "react-helmet-async";
// import AwayAlert from "../components/AwayMessage";


function PaintingShop() {
  return (
    <div>
      <Helmet>
        <title>Tyrone Layne | Shop | Paintings</title>
        <link rel='canonical' href='http://tyronelayne.com/painting-shop' />
      </Helmet>
      <HeaderSpacer />
      {/* <AwayAlert
        message="Attention- I'll be away from the studio Feb 9-19th. So any prints
        ordered after midday Feb 8th(SYD Time) will be shipped as soon as I
        return to Sydney."
      /> */}
      <ImageLink
        source={parisOriginalPic}
        description="Click to view 'Paris: A Moveable Feast- 2024'"
        cssClass='parisOriginalPic'
        linkTo='/painting-shop/ParisOriginal'
        label='Paris: A Moveable Feast- 2024'
        sold={true}
      />
      <ImageLink
        source={optimist2Pic}
        description="Click to view 'The Optimist II- 2023/2024'"
        cssClass='optimist2Pic'
        linkTo='/painting-shop/Optimist2Original'
        label='The Optimist II - 2023/2024'
      />
      <ImageLink
        source={miniCapsulePic}
        description="Click to view the '10inch x 10inch Capsule Release'"
        cssClass='miniCapsulePic'
        linkTo='/painting-shop/miniCapsuleOriginal'
        label='10inch x 10inch Capsule Series 2024'
      />
      <ImageLink
        source={betootaPic}
        description="Click to view 'Betoota Heights 2022'"
        cssClass='betootaPic'
        linkTo='/painting-shop/betoota'
        label='Betoota Heights - 2022'
        sold={true}
      />
      <UpButton linkTo='/shop' />
    </div>
  );
}

export default PaintingShop;
