import i1 from "../../images/shop/Stockroom/1_untitled.jpg";
import i2 from "../../images/shop/Stockroom/2_untitled.jpg";
import i3 from "../../images/shop/Stockroom/3_untitled.jpg";
import i4 from "../../images/shop/Stockroom/4_untitled.jpg";
import i5 from "../../images/shop/Stockroom/5_untitled.jpg";
import i6 from "../../images/shop/Stockroom/6_untitled.jpg";
import i7 from "../../images/shop/Stockroom/7_untitled.jpg";
import i8 from "../../images/shop/Stockroom/8_untitled.jpg";
import i9 from "../../images/shop/Stockroom/9_untitled.jpg";
import i10 from "../../images/shop/Stockroom/10_untitled.jpg";
import i11 from "../../images/shop/Stockroom/11_untitled.jpg";
import i12 from "../../images/shop/Stockroom/12_untitled.jpg";
import i13 from "../../images/shop/Stockroom/13_untitled.jpg";
import i14 from "../../images/shop/Stockroom/14_untitled.jpg";
import i15 from "../../images/shop/Stockroom/15_untitled.jpg";

const miniCapsuleOriginalCollection = [
  { image: i1, alt: "Mini Capsule" },
  { image: i2, alt: "Mini Capsule" },
  { image: i3, alt: "Mini Capsule" },
  { image: i4, alt: "Mini Capsule" },
  { image: i5, alt: "Mini Capsule" },
  { image: i6, alt: "Mini Capsule" },
  { image: i7, alt: "Mini Capsule" },
  { image: i8, alt: "Mini Capsule" },
  { image: i9, alt: "Mini Capsule" },
  { image: i10, alt: "Mini Capsule" },
  { image: i11, alt: "Mini Capsule" },
  { image: i12, alt: "Mini Capsule" },
  { image: i13, alt: "Mini Capsule" },
  { image: i14, alt: "Mini Capsule" },
  { image: i15, alt: "Mini Capsule" },
];

export default miniCapsuleOriginalCollection;
